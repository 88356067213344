const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'EUR',
});

export const priceFormat = (
  price: number | undefined,
  prefix = true
): string => {
  if (!price && price !== 0) {
    return '';
  }
  const formatted = formatter.format(price);
  if (!prefix) {
    return formatted.slice(1, formatted.length);
  }
  return formatted;
};
