import cx from 'classnames';
import { filter, find, first, flatten, isEmpty, map, merge } from 'lodash';
import React, {
  FormEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import CTAButton from 'src/components/widgets/cta-button';
import { priceFormat } from 'src/util/price-format';
import { productAvailableQty, shopifyClient } from 'src/util/shopify-client';
import { ICta } from 'typings/generated/contentful';

import LoadingIndicator from './loading-indicator';

const getSelectedOptions = (variant?: ShopifyBuy.ProductVariant): any[] =>
  variant ? (variant as any).selectedOptions : [];

const getDiscountPrice = (basePrice: number, discount: number) =>
  Number(basePrice) * (1 - discount / 100);

function Badge({ text, color, className }): ReactElement {
  return (
    <span
      className={cx(
        'px-2 py-1 whitespace-no-wrap font-medium text-white rounded',
        className
      )}
      style={{ backgroundColor: color }}
    >
      {text}
    </span>
  );
}

function Table({ children }): ReactElement {
  return (
    <div className="overflow-hidden border rounded border-primary">
      {children}
    </div>
  );
}

function TableHeader({ children }): ReactElement {
  return (
    <div className="px-4 py-2 text-xl font-bold text-white bg-primary">
      {children}
    </div>
  );
}

function Label({ children }): ReactElement {
  return <div className="text-lg font-bold">{children}</div>;
}

interface Props {
  shopJson: IShopJSON;
  onSummaryChange: (summary: IShopSummary) => void;
}

function Shop({ shopJson, onSummaryChange }: Props): ReactElement | null {
  const [shopifyData, setShopifyData] = useState<InventoryProduct | null>(null);
  const [selection, setSelection] = useState<{ [name: string]: string }>({});
  const [quantity, setQuantity] = useState<number>(1);
  const [submitProgress, setSubmitProgress] = useState(false);

  useEffect(() => {
    if (!shopifyData) {
      return;
    }
    setSelection(
      shopifyData.options.reduce((acc, curr) => {
        const availableVariant = first(
          filter(shopifyData.variants, 'available')
        );
        return {
          ...acc,
          [curr.name]: find(
            getSelectedOptions(availableVariant),
            (option) => option.name === curr.name
          )?.value,
        };
      }, {})
    );
  }, [shopifyData]);

  useEffect(() => {
    Promise.all([
      shopifyClient.product.fetch(shopJson.productId),
      productAvailableQty('bettwasche-set-1'),
    ]).then(([base, availability]) => {
      setShopifyData(merge({}, base, availability));
    });
  }, []);

  const selectedVariant = useMemo(() => {
    if (!shopifyData) {
      return null;
    }
    return (
      find(shopifyData.variants, (variant) =>
        getSelectedOptions(variant).every(
          ({ name, value }) => selection[name] === value
        )
      ) || null
    );
  }, [selection, shopifyData]);

  useEffect(() => {
    if (selectedVariant && shopJson) {
      if (quantity > selectedVariant.quantityAvailable) {
        setQuantity(selectedVariant.quantityAvailable);
      }
    }
  }, [selectedVariant]);

  useEffect(() => {
    if (selectedVariant) {
      const quantityLine = find(shopJson.tables[2].data, { value: quantity });
      const badge = find(shopJson.tables[2].data, 'badge');
      onSummaryChange({
        values: Object.values(selection),
        quantityText: quantityLine.text,
        badge: badge ? <Badge {...badge.badge} /> : null,
        variant: selectedVariant,
      });
    }
  }, [selectedVariant, selection, quantity]);

  const handleSubmit = useCallback<(e: FormEvent<HTMLFormElement>) => void>(
    (e) => {
      e.preventDefault();
      setSubmitProgress(true);
      const variant = shopifyData?.variants.find((variant) => {
        return getSelectedOptions(variant).every(
          ({ name, value }) => selection[name] === value
        );
      });
      if (!variant) {
        return;
      }
      shopifyClient.checkout
        .create()
        .then((cart) =>
          shopifyClient.checkout.addLineItems(cart.id, [
            {
              quantity,
              variantId: variant?.id,
            },
          ])
        )
        .then((cart) =>
          (shopifyClient.checkout as any).addDiscount(
            cart.id,
            shopJson.discounts[quantity].code
          )
        )
        .then((cart) => {
          window.location.href = cart.webUrl;
        })
        .finally(() => setSubmitProgress(false));
    },
    [shopifyData, selection, quantity]
  );

  if (!shopifyData || !selectedVariant || isEmpty(selection)) {
    return (
      <div className="w-full h-screen">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <form className="flex flex-col w-full space-y-4" onSubmit={handleSubmit}>
      {shopifyData.options.map((option, i) => {
        return (
          <Table key={option.name}>
            <TableHeader>{shopJson.tables[i].title}</TableHeader>
            {(option.values as any).map(({ value }, j, list) => {
              const variant = find(shopifyData.variants, (variant) =>
                getSelectedOptions(variant).every(({ name, value: mValue }) => {
                  if (name === option.name) {
                    return mValue === value;
                  }
                  return selection[name] === mValue;
                })
              );
              if (!variant) {
                return true;
              }
              return (
                <div
                  className={cx('flex items-center px-2', {
                    'bg-blue-100': selection[option.name] === value,
                    'border-b border-primary': j < list.length - 1,
                  })}
                  key={value}
                >
                  <input
                    checked={selection[option.name] === value}
                    className="w-6 h-6"
                    disabled={!variant.available}
                    id={value}
                    name={option.name}
                    onChange={() => {
                      setSelection((s) => ({ ...s, [option.name]: value }));
                    }}
                    type="radio"
                    value={value}
                  />
                  <label
                    className="flex items-center justify-between flex-1 px-4 py-4 cursor-pointer"
                    htmlFor={value}
                  >
                    <div className="flex flex-col flex-wrap">
                      <Label>{value}</Label>
                      {shopJson.tables[i].type !== 'color' && (
                        <div>
                          {flatten([shopJson.tables[i].data[j] as any]).reduce(
                            (acc, curr, i) => [
                              ...acc,
                              curr.text,
                              curr.badge && <br className="block md:hidden" />,
                              curr.badge && (
                                <Badge
                                  className="md:ml-2"
                                  color={curr.badge.color}
                                  key={`${i}-badge`}
                                  text={curr.badge.text}
                                />
                              ),
                              <br key={`${i}-br`} />,
                            ],
                            []
                          )}
                        </div>
                      )}
                    </div>
                    {!variant.available && (
                      <div className="text-red-500">
                        {shopJson.strings['out of stock']}
                      </div>
                    )}
                    {variant.available && shopJson.tables[i].type === 'price' && (
                      <div className="flex flex-col text-right">
                        <div className="font-bold line-through">
                          {shopJson.strings['base price']}{' '}
                          <span>{variant.price.amount}</span>
                        </div>
                        <div>
                          {shopJson.strings['only']}{' '}
                          <span>
                            {priceFormat(
                              getDiscountPrice(
                                variant.price.amount,
                                shopJson.discounts[quantity].value
                              )
                            )}
                          </span>
                          <span className="text-red-500">
                            {` (-${shopJson.discounts[quantity].value}%)`}
                          </span>
                        </div>
                      </div>
                    )}
                    {variant.available && shopJson.tables[i].type === 'color' && (
                      <div className="flex items-center justify-center">
                        <div
                          className="w-24 h-10 border rounded border-primary"
                          style={{
                            backgroundColor: (shopJson.tables[i].data[j] as any)
                              .colorSample,
                          }}
                        />
                      </div>
                    )}
                  </label>
                </div>
              );
            })}
          </Table>
        );
      })}
      <Table>
        <TableHeader>{shopJson.tables[2].title}</TableHeader>
        {map(shopJson.tables[2].data, 'value').map((value, i) => {
          const soldOut = value > selectedVariant.quantityAvailable;
          const reducedPrice = getDiscountPrice(
            selectedVariant.price.amount,
            shopJson.discounts[value].value
          );
          const { badge } = shopJson.tables[2].data[i];
          return (
            <div
              className={cx('flex items-center px-2 border-b border-primary', {
                'bg-blue-100': value === quantity,
              })}
              key={value.toString()}
            >
              <input
                checked={value === quantity}
                className="w-6 h-6"
                disabled={soldOut}
                id={`quantity-${value}`}
                name="quantity"
                onChange={(e) => {
                  setQuantity(parseInt(e.target.value));
                }}
                type="radio"
                value={value.toString()}
              />
              <label
                className="flex items-center justify-between flex-1 px-2 py-4 cursor-pointer md:px-4"
                htmlFor={`quantity-${value}`}
              >
                <div className="flex flex-col space-y-1">
                  <Label>{shopJson.tables[2].data[i].text}</Label>
                  {shopJson.tables[2].data[i].subtext.badge && (
                    <div>
                      <Badge {...shopJson.tables[2].data[i].subtext.badge} />
                    </div>
                  )}
                  {shopJson.tables[2].data[i].subtext && (
                    <div
                      className="font-medium"
                      style={{
                        color: shopJson.tables[2].data[i].subtext.color,
                      }}
                    >
                      {shopJson.tables[2].data[i].subtext.text}
                    </div>
                  )}
                </div>
                {soldOut && (
                  <div className="text-red-500">
                    {shopJson.strings['out of stock']}
                  </div>
                )}
                {!soldOut && (
                  <>
                    <div className="text-sm font-bold text-right md:text-base">
                      <div>
                        {priceFormat(reducedPrice)}
                        {shopJson.strings['per set']}
                      </div>
                      <div>
                        {shopJson.strings.total}{' '}
                        {priceFormat(reducedPrice * value)}
                      </div>
                      {badge && (
                        <div className="relative h-4">
                          <Badge
                            className="absolute right-0 text-center"
                            {...badge}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </label>
            </div>
          );
        })}
        <div className="flex justify-center py-4">
          <CTAButton
            button={
              {
                fields: {
                  animated: false,
                  copy: shopJson.strings['to checkout'],
                },
              } as ICta
            }
            className="w-full"
            disabled={
              !selectedVariant.available ||
              quantity > selectedVariant.quantityAvailable ||
              submitProgress
            }
            progress={submitProgress}
            smaller
            type="submit"
          />
        </div>
      </Table>
    </form>
  );
}

export default Shop;
