import GatsbyImage from 'gatsby-image';
import React, { ReactElement } from 'react';
import { getFluidImage } from 'src/util/get-fluid-image';
import { ISectionPromo } from 'typings/generated/contentful';

import SectionContent from '../widgets/section-content';

interface Props {
  section: ISectionPromo;
}

function Promo({ section }: Props): ReactElement {
  return (
    <section>
      <SectionContent className="pb-2" padding={false}>
        <GatsbyImage
          className="w-full h-32 max-w-screen-lg md:h-40 contain"
          fluid={getFluidImage(section.fields.media, { w: null, fm: null })}
        />
      </SectionContent>
    </section>
  );
}

export default Promo;
