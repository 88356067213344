import React, { ReactElement } from 'react';

function Loader(): ReactElement {
  return (
    <svg
      height="64px"
      version="1.0"
      viewBox="0 0 128 32"
      width="128px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="#fff" height="100%" width="100%" x="0" y="0" />
      <circle
        className="fill-current"
        cx="0"
        cy="0"
        fillOpacity="1"
        r="11"
        transform="translate(16 16)"
      >
        <animateTransform
          additive="sum"
          attributeName="transform"
          dur="1350ms"
          repeatCount="indefinite"
          type="scale"
          values="1;1.42;1;1;1;1;1;1;1;1"
        ></animateTransform>
      </circle>
      <circle
        className="fill-current"
        cx="0"
        cy="0"
        fillOpacity="1"
        r="11"
        transform="translate(64 16)"
      >
        <animateTransform
          additive="sum"
          attributeName="transform"
          dur="1350ms"
          repeatCount="indefinite"
          type="scale"
          values="1;1;1;1;1.42;1;1;1;1;1"
        ></animateTransform>
      </circle>
      <circle
        className="fill-current"
        cx="0"
        cy="0"
        fillOpacity="1"
        r="11"
        transform="translate(112 16)"
      >
        <animateTransform
          additive="sum"
          attributeName="transform"
          dur="1350ms"
          repeatCount="indefinite"
          type="scale"
          values="1;1;1;1;1;1;1;1.42;1;1"
        ></animateTransform>
      </circle>
    </svg>
  );
}

export default Loader;
