import React, { ReactElement } from 'react';

import Loader from '../svg/loader';

function LoadingIndicator(): ReactElement {
  return (
    <div className="flex justify-center pt-16 pb-8 text-x-orange">
      <Loader />
    </div>
  );
}

export default LoadingIndicator;
