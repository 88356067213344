import cx from 'classnames';
import GatsbyImage from 'gatsby-image';
import React, { ReactElement } from 'react';
import { getFluidImage } from 'src/util/get-fluid-image';
import { ISectionTestimonials } from 'typings/generated/contentful';

import CTAButton from '../widgets/cta-button';
import SectionContent from '../widgets/section-content';
import SectionTitle from '../widgets/section-title';
import Testimonial from '../widgets/testimonial';

interface Props {
  section: ISectionTestimonials;
}

function Testimonials({ section }: Props): ReactElement {
  return (
    <section
      className={cx({ 'bg-bg-light': !section.fields.visualization })}
      id={section.fields.anchor}
    >
      <SectionContent className="pt-16" padding={false}>
        <SectionTitle sectionData={section.fields.sectionData} />
        {section.fields.visualization && (
          <div className="w-full h-64 mt-8 lg:mt-16 lg:h-32">
            <GatsbyImage
              className="w-full h-full contain"
              fluid={getFluidImage(section.fields.visualization, { fm: null })}
            />
          </div>
        )}
        <div className="flex justify-center w-screen px-8 pb-16 mt-8 bg-bg-light sm:mt-16">
          <div className="flex flex-col w-full max-w-screen-md">
            {section.fields.testimonials?.map((testimonial, i, list) => (
              <Testimonial
                borderBottom={i < list.length - 1}
                key={testimonial.sys.id}
                starIcon={section.fields.starIcon}
                testimonial={testimonial}
                verifiedBuyer={section.fields.verifiedBuyer}
              />
            ))}
          </div>
        </div>
        <CTAButton button={section.fields.ctaButton} className="mb-16" />
      </SectionContent>
    </section>
  );
}

export default Testimonials;
