import cx from 'classnames';
import React, { ReactElement } from 'react';

interface Props {
  className?: string;
  checkFill?: string;
}

export const Checkmark = ({ className, checkFill }: Props): ReactElement => {
  return (
    <svg
      className={cx('fill-current', className)}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill-current"
        d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0ZM10 18.1818C5.48848 18.1818 1.81818 14.5115 1.81818 10C1.81818 5.48855 5.48848 1.81818 10 1.81818C14.5115 1.81818 18.1818 5.48855 18.1818 10C18.1818 14.5115 14.5115 18.1818 10 18.1818Z"
      />
      <path
        className={checkFill ? undefined : 'fill-current'}
        d="M13.7498 6.46546L8.60712 11.6081L6.25015 9.25104C5.89518 8.89607 5.31955 8.89601 4.96451 9.25098C4.60948 9.60601 4.60948 10.1816 4.96451 10.5366L7.96427 13.5365C8.13476 13.707 8.36597 13.8028 8.60706 13.8028C8.60712 13.8028 8.60706 13.8028 8.60712 13.8028C8.84821 13.8028 9.07942 13.707 9.24991 13.5366L15.0354 7.75116C15.3905 7.39613 15.3905 6.82055 15.0354 6.46552C14.6804 6.11049 14.1048 6.11043 13.7498 6.46546Z"
        fill={checkFill}
      />
    </svg>
  );
};
