import { useWindowWidth } from '@react-hook/window-size';
import GatsbyImage from 'gatsby-image';
import React, { ReactElement } from 'react';
import { getFluidImage } from 'src/util/get-fluid-image';
import { IHeroSection } from 'typings/generated/contentful';

import { Checkmark } from '../svg/checkmark';
import SectionContent from '../widgets/section-content';

interface Props {
  section: IHeroSection;
}

function HeroBanner({ section }: Props): ReactElement {
  const width = useWindowWidth({ initialWidth: 0 });
  return (
    <section>
      <div className="relative lg:py-4 xl:py-0">
        {/* background image */}
        <div className="absolute top-0 bottom-0 left-0 right-0 pointer-events-none">
          <GatsbyImage
            className="h-full img-right-100"
            fluid={getFluidImage(section.fields.backgroundImage, { w: width })}
          />
        </div>
        <SectionContent center={false} padding={false}>
          <div className="relative grid w-3/5 grid-cols-2 text-white gap-y-4 xl:py-8 xl:w-4/5">
            <h1 className="col-span-2 mb-4 text-3xl font-bold">
              {section.fields.mainHeader}
            </h1>
            <div className="flex space-x-8">
              {/* 2 columns, fill columns from left to right */}
              {['even', 'odd'].map((key, i) => (
                <div className="flex flex-col space-y-2" key={key}>
                  {section.fields.productBullets
                    ?.filter((v, j) => j % 2 === i)
                    .map((bullet) => (
                      <div
                        className="flex items-center whitespace-no-wrap"
                        key={bullet}
                      >
                        <div className="mr-2">
                          <Checkmark />
                        </div>
                        {bullet}
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>
        </SectionContent>
      </div>
    </section>
  );
}

export default HeroBanner;
