import GatsbyImage from 'gatsby-image';
import React, { ReactElement, useState } from 'react';
import { getFluidImage } from 'src/util/get-fluid-image';
import { ISectionBulletPoints } from 'typings/generated/contentful';

import { Checkmark } from '../svg/checkmark';
import SectionContent from '../widgets/section-content';
import SectionTitle from '../widgets/section-title';
import Shop from '../widgets/shop';

interface Props {
  section: ISectionBulletPoints;
  shopJson: IShopJSON;
}

function ShopBulletPoints({ section, shopJson }: Props): ReactElement {
  const [summary, setSummary] = useState<IShopSummary | null>(null);

  return (
    <section id={section.fields.anchor}>
      <SectionContent className="md:py-8" padding={false}>
        {section.fields.sectionData && (
          <SectionTitle sectionData={section.fields.sectionData} />
        )}
        <div className="flex flex-col justify-center w-full max-w-screen-lg space-y-8 md:space-x-8 md:flex-row md:space-y-0">
          <div className="md:w-3/5">
            <Shop onSummaryChange={setSummary} shopJson={shopJson} />
          </div>
          <div className="md:w-2/5">
            <div className="hidden pb-8 border-b border-dashed md:flex border-bg-dark">
              <div className="flex flex-col flex-1 space-y-2">
                <div className="text-xl font-bold">{summary?.quantityText}</div>
                {summary?.values.map((value) => (
                  <div key={value}>{value}</div>
                ))}
                <div>{summary?.badge}</div>
              </div>
              <div className="flex justify-center flex-1">
                <img
                  alt={summary?.variant.title}
                  className="object-contain w-32 h-32"
                  src={summary?.variant.image?.src}
                />
              </div>
            </div>
            <div className="flex flex-col space-y-4 md:mt-8">
              {section.fields.bullets?.map((text) => (
                <div className="flex items-center" key={text}>
                  <div>
                    <Checkmark
                      checkFill="green"
                      className="w-6 h-6 mr-1 text-white"
                    />
                  </div>
                  {text}
                </div>
              ))}
            </div>
            <div className="w-full my-8">
              <GatsbyImage
                className="w-full contain"
                fluid={getFluidImage(section.fields.sectionImage, {
                  q: null,
                  fm: null,
                })}
              />
            </div>
          </div>
        </div>
      </SectionContent>
    </section>
  );
}

export default ShopBulletPoints;
