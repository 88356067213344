import cx from 'classnames';
import { Asset } from 'contentful';
import GatsbyImage from 'gatsby-image';
import React, { ReactElement } from 'react';
import { getFluidImage } from 'src/util/get-fluid-image';
import { ITestimonial, IVerifiedBuyer } from 'typings/generated/contentful';

interface Props {
  testimonial: ITestimonial;
  starIcon: Asset | undefined;
  verifiedBuyer: IVerifiedBuyer | undefined;
  borderBottom: boolean;
}

function Testimonial({
  testimonial,
  starIcon,
  borderBottom,
  verifiedBuyer,
}: Props): ReactElement {
  return (
    <div
      className={cx('flex flex-col pb-8 md:pb-0 overflow-hidden', {
        'border-b-2': borderBottom,
      })}
    >
      <div className="flex items-center justify-center w-full py-4 space-x-2 md:py-8 md:space-x-8">
        <div className="flex flex-col flex-1">
          <div className="text-2xl font-bold">
            {testimonial.fields.firstName} {testimonial.fields.lastName}
          </div>
          <div className="mt-1">{testimonial.fields.location}</div>
          <div className="flex flex-col mt-2 space-y-2 sm:space-y-0 sm:flex-row">
            <div className="flex items-center">
              {Array.from({ length: testimonial.fields.stars as number }).map(
                (v, i) => (
                  <span className="mr-px" key={i}>
                    <GatsbyImage
                      className="w-4 h-4"
                      fluid={getFluidImage(starIcon, { fm: null })}
                    />
                  </span>
                )
              )}
            </div>
            <div className="flex">
              <span className="mr-2 sm:ml-2">
                <GatsbyImage
                  alt={verifiedBuyer?.fields.icon?.fields.title}
                  className="w-5 h-5"
                  fluid={getFluidImage(verifiedBuyer?.fields.icon, {
                    fm: null,
                  })}
                />
              </span>
              <span className="text-sm">{verifiedBuyer?.fields.copy}</span>
            </div>
          </div>
          <div className="hidden mt-4 md:block">
            <div className="text-lg font-bold">{testimonial.fields.title}</div>
            <p className="mt-2">{testimonial.fields.testimonial}</p>
          </div>
        </div>
      </div>
      <div className="block md:hidden">
        <div className="text-lg font-bold">{testimonial.fields.title}</div>
        <p className="mt-2">{testimonial.fields.testimonial}</p>
      </div>
    </div>
  );
}

export default Testimonial;
