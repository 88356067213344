import { Asset } from 'contentful';
import React, { ReactElement, useCallback } from 'react';
import Footer from 'src/components/sections/footer';
import Navbar from 'src/components/sections/navbar';
import Testimonials from 'src/components/sections/testimonials';
import HeroBanner from 'src/components/shop/hero-banner';
import Promo from 'src/components/shop/promo';
import ShopBulletPoints from 'src/components/shop/shop-bullets';
import Favicon from 'src/components/widgets/favicon';
import SEO from 'src/components/widgets/seo';
import {
  ICheckoutPage,
  IHeroSection,
  ISectionBulletPoints,
  ISectionFooter,
  ISectionHeader,
  ISectionPromo,
  ISectionTestimonials,
  IStaticPages,
} from 'typings/generated/contentful';

interface Props {
  location: Location;
  pageContext: {
    reactContext: {
      staticPages: IStaticPages[];
    };
    checkoutPage: ICheckoutPage;
    favicon: Asset;
  };
}

export default function ShopPage(props: Props): ReactElement | null {
  const {
    location,
    pageContext: { checkoutPage, favicon },
  } = props;

  const renderSection = useCallback((section) => {
    switch (section.sys.contentType.sys.id) {
      case 'sectionHeader':
        return <Navbar section={section as ISectionHeader} />;
      case 'heroSection':
        return <HeroBanner section={section as IHeroSection} />;
      case 'sectionBulletPoints':
        return (
          <ShopBulletPoints
            section={section as ISectionBulletPoints}
            shopJson={checkoutPage.fields.shopJson as IShopJSON}
          />
        );
      case 'sectionPromo':
        return <Promo section={section as ISectionPromo} />;
      case 'sectionTestimonials':
        return <Testimonials section={section as ISectionTestimonials} />;
      case 'sectionFooter':
        return <Footer section={section as ISectionFooter} />;
      default:
        return null;
    }
  }, []);

  return (
    <div className="flex flex-col text-primary">
      <Favicon icon={favicon} />
      <SEO canonical={location.href} seo={checkoutPage.fields.seoInformation} />
      <div className="hidden lg:block">
        {checkoutPage.fields.content?.map((section) => renderSection(section))}
      </div>
      <div className="block lg:hidden">
        {checkoutPage.fields.contentMobile?.map((section) =>
          renderSection(section)
        )}
      </div>
    </div>
  );
}
